import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import { initKeycloak } from '@/helpers/keycloak';
import { getSubdomain } from './helpers/util';
import App from './App.vue'

const getManifest = () => {
    const hostparts = window.location.href.split('happit.app')
    let hosturl = `${hostparts[0]}happit.app`;
    hosturl = import.meta.env.VITE_NAME === 'local' ? 'http://localhost:3000' : hosturl
    let manifest = {
        "start_url": `${hosturl}/installations`,
        "display": "standalone",
        "background_color": "#ffffff",
        "lang": "en",
        "scope": `${hosturl}/`,
        "theme_color": "#ffffff",
    }

    const appName = getSubdomain();
    manifest.name = appName;
    manifest.short_name = appName;
    manifest.description = appName;

    let icons = [];
    icons.push({
        "src": `${hosturl}/energreen.svg`,
        "sizes": "64x64",
        "type":"image/svg+xml"
    });
    icons.push({
        "src": `${hosturl}/energreen.svg`,
        "sizes": "192x192",
        "type":"image/svg+xml"
    });
    icons.push({
        "src": `${hosturl}/energreen.svg`,
        "sizes": "512x512",
        "type":"image/svg+xml",
    });
    icons.push({
        "src": `${hosturl}/energreen.png`,
        "sizes": "512x512",
        "type":"image/png",
        "purpose":"any"
    });

    manifest.icons = icons;
    let content = encodeURIComponent(JSON.stringify(manifest));
    let url = "data:application/manifest+json,"+content;
    return url;
}

const setManifest = () => {
    let url = getManifest();
    document.querySelector('#my-manifest').setAttribute('href', url);
};

(async () => {
    await initKeycloak();

    const app = createApp(App);
    registerPlugins(app);

    app.mount('#app');

    setManifest()
})();
