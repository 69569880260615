import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@plugins/api-client'
import { getApiRoot, getApiUrl } from '@/helpers/api'
import { useOfferStore } from '@/store/offers'
import { useOrderStore } from '@/store/orders'
import { useDeliveryStore } from '@/store/deliveries'
import { InvoiceStatuses } from '@/store/invoices'
import { InspectionStatuses, NetOperatorStatuses } from '@/store/inspections'
import {
  getLocalstorageItem,
  localStorageKeys,
  removeLocalstorageItem,
  setLocalstorageItem
} from '@/helpers/localStorage'
import { useUserStore } from '@/store/user';
import { getKeycloak } from '@/helpers/keycloak';
import { getTimeDifferenceInDays } from '@/helpers/date'

export const InstallationStatuses = {
    New: 1,
    Signed: 2,
    Needs_Prepayment: 3,
    Prepaid: 4,
    Planned: 5,
    Delivered: 6,
    Paid: 7,
    InspectionPlanned: 8,
    NetOperator: 9,
    Completed: 10,
    Ordered: 11
}

export const useInstallationStore = defineStore('installationStore', () => {
    const installations = ref([]);
    const offerStore = useOfferStore();
    const orderStore = useOrderStore();
    const deliveryStore = useDeliveryStore();
    let { getFinalOfferStatus } = offerStore;
    let { getFinalOrderStatus, getInitialOrderStatus } = orderStore;
    let { getFinalDeliveryStatus } = deliveryStore;


    async function setInstallationsStatus(installations){
        const InitialOrderStatus = await getInitialOrderStatus();
        const FinalOfferStatus = await getFinalOfferStatus();
        const FinalOrderStatus = await getFinalOrderStatus();
        const FinalDeliveryStatus = await getFinalDeliveryStatus();

        installations.forEach((installation, index) => {
            installation.id = index;
            if(installation.OfferID > 0){
                if(installation.OfferStatus == FinalOfferStatus){
                    installation.status = InstallationStatuses.Signed;
                }
                else{
                    installation.status = InstallationStatuses.New;
                }
            }

            if(installation.OrderID > 0 && installation.OrderStatus > InitialOrderStatus) {
                if(installation.PrepaymentInvoiceID > 0 && installation.PrepaymentInvoiceStatus != InvoiceStatuses.Paid){
                    installation.status = InstallationStatuses.Needs_Prepayment
                }
                else if(installation.PrepaymentInvoiceID > 0 && installation.PrepaymentInvoiceStatus === InvoiceStatuses.Paid){
                    installation.status = InstallationStatuses.Prepaid
                }
                else{
                    installation.status = InstallationStatuses.Ordered;
                }
            }

            if(installation.Order_DeliveriesID > 0){
                const diffDays = getTimeDifferenceInDays(new Date(installation.InstallationDate), Date.now());
                
                if(installation.InstallationDate != null){
                    installation.status = InstallationStatuses.Planned;
                }
                if(installation.QR_FilledIn || diffDays >= 1 || installation.InvoiceID > 0){
                    installation.status = InstallationStatuses.Delivered;
                }
            }

            if(installation.InvoiceID > 0 && installation.QR_FilledIn){
                if(installation.InvoiceStatus == InvoiceStatuses.Paid){
                    installation.status = InstallationStatuses.Paid;
                }
                else{
                    installation.status = InstallationStatuses.Delivered;
                }
            }

            if((installation.InspectionHistoryID > 0 || Number(installation.OrderInspectionJobID) > 0 || Number(installation.InvoiceInspectionJobID) > 0)
                && installation.InvoiceStatus == InvoiceStatuses.Paid
                ){
                installation.inspectionOnOrder = installation.OrderInspectionJobID > 0;
                installation.inspectionOnInvoice = installation.InvoiceInspectionJobID > 0;
                installation.inspectionOnHistory = installation.InspectionHistoryID > 0;

                if(installation.inspectionOnHistory && installation.InspectionStatus == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else{
                    installation.status = InstallationStatuses.InspectionPlanned;
                }
            }

            if(installation.NetOperatorStatus === NetOperatorStatuses.Completed) {
                installation.status = InstallationStatuses.Completed;
            }
        });
        return installations;
    }

    function updateInstallationIds(installations) {
        installations.forEach(installation => {
            installation.CustomersID = Number(installation.CustomersID);
            installation.InspectionHistoryID = Number(installation.InspectionHistoryID);
            installation.InvoiceID = Number(installation.InvoiceID);
            installation.InvoiceInspectionJobID = Number(installation.InvoiceInspectionJobID);
            installation.OfferID = Number(installation.OfferID);
            installation.OrderID = Number(installation.OrderID);
            installation.Order_DeliveriesID = Number(installation.Order_DeliveriesID);
        })
        return installations;
    }

    async function getInstallations() {
        const localStorageInstallations = getLocalstorageItem(localStorageKeys.installations);

        if (localStorageInstallations) {
            installations.value = localStorageInstallations;
            return;
        }

        try {
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/installations/`,
                { headers }
            )
            if (response.data.errors.length > 0) {
                throw new Error(response.data.errors)
            }
            let reponseInstallations = await setInstallationsStatus(response.data.data);
            reponseInstallations = updateInstallationIds(reponseInstallations);
            installations.value = reponseInstallations;
            setLocalstorageItem(localStorageKeys.installations, reponseInstallations);
        } catch (error) {
            throw new Error(error);
        }
    }

    async function refreshInstallations(){
        removeLocalstorageItem(localStorageKeys.installations);
        await getInstallations();
    }

    async function getInstallationByID(id){
        await getInstallations();
        return installations.value.find((installation) => installation.id == id);
    }

    async function getInstallationOffer(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OfferID: installation.OfferID == undefined ? NaN : installation.OfferID,
            CurrentStatusID: installation.OfferStatus,
            OfferVersionNumbers: installation.OfferVersionNumbers,
            OfferActiveVersionNumber: installation.VersionNumber,
            Offer_TypeID: installation.Offer_TypeID,
        }
    }

    async function getInstallationOrder(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OrderID: installation.OrderID == undefined ? NaN : installation.OrderID,
            CurrentStatusID: installation.OrderStatus,
            PrepaymentID: installation.PrepaymentInvoiceID,
            PrepaymentStatus: installation.PrepaymentInvoiceStatus,
            plannedDate: installation.InstallationDate
        }
    }

    async function getInstallationDelivery(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        const diffDays = getTimeDifferenceInDays(new Date(installation.InstallationDate), Date.now());
        return {
            Order_DeliveriesID: installation.Order_DeliveriesID == undefined ? NaN : installation.Order_DeliveriesID,
            CurrentStatusID: installation.DeliveryStatus,
            Finished: installation.Order_DeliveriesID > 0 && (installation.QR_FilledIn || diffDays >= 1 || installation.InvoiceID > 0)
        }
    }

    async function getInstallationInvoice(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            InvoiceID: installation.InvoiceID == undefined ? NaN : installation.InvoiceID,
            CurrentStatusID: installation.InvoiceStatus
        }
    }

    async function getInstallationInspection(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);

        const inspectionID = installation.inspectionOnOrder ? installation.OrderID :
                                installation.inspectionOnInvoice ? installation.InvoiceID :
                                    installation.inspectionOnHistory ? installation.InspectionHistoryID : NaN;

        const inspectionSource = installation.inspectionOnOrder ? 'order' :
                                    installation.inspectionOnInvoice ? 'invoice' :
                                        installation.inspectionOnHistory ? 'inspection' : '';

        let inspectionStatus = InspectionStatuses.Requested;

       
        if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }
        else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }
        else if(installation.inspectionOnHistory) {
            inspectionStatus = installation.InspectionStatus;
        }

        const NetOperatorStatus = installation.NetOperatorStatus;

        return {
            InspectionID: inspectionID,
            InspectionSource: inspectionSource,
            InspectionStatus: inspectionStatus,
            NetOperatorStatus: NetOperatorStatus,
        }
    }

    return {
        installations,
        refreshInstallations,
        getInstallations,
        getInstallationByID,
        getInstallationOffer,
        getInstallationOrder,
        getInstallationDelivery,
        getInstallationInvoice,
        getInstallationInspection
    }
})
