import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@plugins/api-client'
import { getApiRoot } from '@helpers/api'
import { getKeycloak } from '@/helpers/keycloak';
import { getSubdomain } from '@/helpers/util'
import { useInstallationStore } from './installations'
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage'

export const useRepairsStore = defineStore('repairsStore', () => {
    const repairs = ref([]);
    const StatusExpirationDays = 10;

    const installationStore = useInstallationStore();
    const { getInstallationByID } = installationStore

    async function getRepairs(installationID) {
        try{
            const installation = await getInstallationByID(installationID);

            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/repairs/${installation.Order_DeliveriesID}/${installation.InvoiceID}`, 
                { headers }
            )
            if(response.data.errors.length > 0){
                throw new Error(response.data.errors)
            }

            repairs.value = response.data.data;
        } catch(error){
            throw new Error(error);
        }
    }

    async function getCustomerRepairs(){
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/repairs/list`, 
                { headers }
            )
            if(response.data.errors.length > 0){
                throw new Error(response.data.errors)
            }

            repairs.value = response.data.data;
        } catch(error){
            throw new Error(error);
        }
    }

    async function getRepairByID(repair_id){
        try {
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/repairs/${repair_id}`,
                { headers }
            )
            if (response.data.errors.length > 0) {
                throw new Error(response.data.errors)
            }
            return response.data.data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async function getRepairStatuses(){
        let repairStatuses = getLocalstorageItem('RepairStatuses');
        if(repairStatuses !== undefined){
            return repairStatuses;
        }
        return (await parseStatusResponse())?.RepairStatuses;
    }

    async function parseStatusResponse(){
        const response = await apiClient.get(
            `${getApiRoot()}/repairs/statuses`, {
                params: {
                    subDomain: getSubdomain(),
                    lang: "nl-BE"
                }
            }
        )
        if(response.data.errors.length == 0){
            let RepairStatuses = {};
            let InitialRepairStatus = null;
            let FinalRepairStatus = null;
            const data = response.data.data;
            for(const status of data) {
                RepairStatuses[status.status.split(' ').join('_')] = status.id;
            }
            InitialRepairStatus = data.filter(status => { return status.initial})[0]?.id;
            FinalRepairStatus = data.filter(status => { return status.final})[0]?.id;
            
            setLocalstorageItem("RepairStatuses", RepairStatuses, StatusExpirationDays);
            setLocalstorageItem("InitialRepairStatus", InitialRepairStatus, StatusExpirationDays);
            setLocalstorageItem("FinalRepairStatus", FinalRepairStatus, StatusExpirationDays);

            return {
                RepairStatuses: RepairStatuses,
                InitialRepairStatus: InitialRepairStatus,
                FinalRepairStatus: FinalRepairStatus
            }
        }
        else{
            throw new Error(response.data.errors)
        }
    }

    async function getInitialRepairStatus() {
        let InitialRepairStatus = getLocalstorageItem("InitialRepairStatus");
        if(InitialRepairStatus !== undefined) {
            return InitialRepairStatus;
        }
        return (await parseStatusResponse())?.InitialRepairStatus;
    }

    async function getFinalRepairStatus() {
        let FinalRepairStatus = getLocalstorageItem("FinalRepairStatus");
        if(FinalRepairStatus !== undefined) {
            return FinalRepairStatus;
        }
        return (await parseStatusResponse())?.FinalRepairStatus;
    }

    return {
        repairs,
        getRepairs,
        getCustomerRepairs,
        getRepairStatuses,
        getInitialRepairStatus,
        getFinalRepairStatus,
        getRepairByID
    }
})