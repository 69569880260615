<template>

    <!--
        On mobile, user is logged in
        Left button, app bar title, right button
    -->
    <v-app-bar v-if="mobile" :elevation="0" class="d-flex align-content-space-evenly px-2" id="navbartop">

        <!-- Left Side -->
        <template v-if="leftNav == 'back'" v-slot:prepend>
            <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-arrow-left"
                @click="router.go(-1)"
            ></v-btn>
        </template>
        <template v-else-if="leftNav == 'close'" v-slot:prepend>
            <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-close"
            ></v-btn>
        </template>
        <template v-else-if="leftNav == 'profile'" v-slot:prepend>
            <v-menu>
                <template v-slot:activator="{props}">
                    <v-btn
                        v-bind="props"
                        size="x-small"
                        variant="tonal"
                        icon="mdi-account-circle-outline"
                    ></v-btn>
                </template>
                <v-list>
                    <v-list-item v-ripple @click="router.push({ name: '/account' })">
                        <v-list-item-title>{{ $t('account') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-ripple @click="logout()">
                        <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <!-- Title -->
        <v-app-bar-title>
            <p class="small-text font-weight-medium">{{ navTitle }}</p>
        </v-app-bar-title>

        <!-- Right side -->
        <template v-if="rightNav == 'home'" v-slot:append class="mx-0">
            <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-gift-outline"
                ></v-btn>
            </div>
            <div>
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-bell-outline"
                ></v-btn>
            </div>
            -->
        </template>
        <template v-else-if="rightNav == 'options-close'" v-slot:append>
            <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
                ></v-btn>
            </div>
            -->
            <div>
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-close"
                ></v-btn>
            </div>

        </template>
        <template v-else-if="rightNav == 'options'" v-slot:append>
            <!--
            <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
            ></v-btn>
            -->
        </template>
        <template v-else-if="rightNav == 'share-download'" v-slot:append>
            <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-share-variant-outline"
                ></v-btn>
            </div>
            -->
            <div>
                <v-btn
                    @click="download()"
                    size="x-small"
                    variant="tonal"
                    icon="mdi-tray-arrow-down"
                    :loading="downloading"
                >
                    <template v-slot:loader>
                        <v-progress-circular color="base800" size="20" width="1" indeterminate></v-progress-circular>
                    </template>
                </v-btn>
            </div>
        </template>
        <template v-else-if="rightNav == 'download'" v-slot:append>
            <v-btn
                @click="download()"
                size="x-small"
                variant="tonal"
                icon="mdi-tray-arrow-down"
                :loading="downloading"
            >
                <template v-slot:loader>
                    <v-progress-circular color="base800" size="20" width="1" indeterminate></v-progress-circular>
                </template>
            </v-btn>
        </template>
    </v-app-bar>

    <!--
        Not on mobile, user is not logged in
        Displays an image as the title
    -->
    <v-app-bar
        v-if="!mobile && !route.meta.requiresAuth"
        :elevation="0"
        class="d-flex align-content-space-evenly px-2"
        color="secondary"
        id="navbartop"
    >
        <v-app-bar-title class="pl-10">
            <Logo></Logo>
        </v-app-bar-title>
    </v-app-bar>

    <!--
        Not on mobile, user is logged in
        Bread crumb navigation and button on the right
    -->
    <div v-if="!mobile" class="d-flex justify-center pt-16" style="max-height: 120px;padding-left: 256px" id="navbartop">
        <div style="min-width: 650px" class="d-flex justify-space-between">
            <div class="d-flex justify-start">
                <template v-if="leftNav == 'back'" class="mx-0">
                    <v-btn
                        size="small"
                        variant="tonal"
                        icon="mdi-arrow-left"
                        @click="router.go(-1)"
                    ></v-btn>
                </template>
            </div>
            <div class="d-flex justify-center">
                <div v-if="!isRootPage">
                    <p class="small-text font-weight-medium">{{ navTitle }}</p>
                </div>
            </div>
            <div class="d-flex justify-end">
                <template v-if="rightNav == 'home'" class="mx-0">
                <!--
                <div class="pr-1">
                    <v-btn
                    size="x-small"
                    variant="tonal"
                    icon="mdi-gift-outline"
                    ></v-btn>
                </div>
                <div>
                    <v-btn
                    size="x-small"
                    variant="tonal"
                    icon="mdi-bell-outline"
                    ></v-btn>
                </div>
                -->
                </template>
                <template v-else-if="rightNav == 'options-close'">
                    <!--
                    <div class="pr-1">
                        <v-btn
                        size="x-small"
                        variant="tonal"
                        icon="mdi-dots-vertical"
                        ></v-btn>
                    </div>
                    -->
                    <div>
                        <v-btn
                        size="x-small"
                        variant="tonal"
                        icon="mdi-close"
                        ></v-btn>
                    </div>

                </template>
                <template v-else-if="rightNav == 'options'">
                    <!--
                    <v-btn
                        size="x-small"
                        variant="tonal"
                        icon="mdi-dots-vertical"
                    ></v-btn>
                    -->
                </template>
                <template v-else-if="rightNav == 'share-download'">
                    <!--
                    <div class="pr-1">
                        <v-btn
                        size="x-small"
                        variant="tonal"
                        icon="mdi-share-variant-outline"
                        ></v-btn>
                    </div>
                    -->
                    <div>
                        <v-btn
                            @click="download()"
                            size="small"
                            variant="tonal"
                            icon="mdi-tray-arrow-down"
                            :loading="downloading"
                        >
                            <template v-slot:loader>
                                <v-progress-circular color="base800" size="20" width="1" indeterminate></v-progress-circular>
                            </template>
                        </v-btn>
                    </div>
                </template>
                <template v-else-if="rightNav == 'download'">
                    <v-btn
                        @click="download()"
                        size="small"
                        variant="tonal"
                        icon="mdi-tray-arrow-down"
                        :loading="downloading"
                    >
                        <template v-slot:loader>
                            <v-progress-circular color="base800" size="20" width="1" indeterminate></v-progress-circular>
                        </template>
                    </v-btn>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import Logo from '@components/Logo'
import BreadCrumbNav from '@components/BreadCrumbNav'
import router from '@/router';
import { useTopNavBarStore } from '@store/topNavBar'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'
import { getKeycloak } from '@/helpers/keycloak';
import { handleDownload } from '@/helpers/util';
import apiClient from '@plugins/api-client'
import {useI18n} from "vue-i18n";
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay();
const route = useRoute();
const i18n = useI18n();

const downloading = ref(false);
const isRootPage = ref(true);

const store = useTopNavBarStore();
const {
    leftNav,
    navTitle,
    rightNav,
    downloadDocument
} = storeToRefs(store);

const userStore = useUserStore();
const { logoutUser } = userStore;

const snackbarStore = useSnackbarStore();
const { showError } = snackbarStore;

watch(
    () => route.fullPath,
    () => {
        const routeParts = route.fullPath.split('/');
        isRootPage.value = routeParts.length == 2;
    }
)

function logout(){
    logoutUser();
}

function openInNewTab(url){
    window.open(url, '_blank').focus();
}

async function generateHappERPFile(){
    downloading.value = true;
    try{
        const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
        const response = await apiClient.get(
            downloadDocument.value.url,
            {
                responseType: 'blob',
                headers
            }
        )
        if(response.status == 200){
            handleDownload(`${downloadDocument.value.text}-${downloadDocument.value.id}.${downloadDocument.value.fileExtension}`, response.data)
        }
        else{
            showError(i18n.t('fileError'));
        }
    } catch(error){
        showError(i18n.t('fileError'));
    }
    downloading.value = false;
}

async function download() {
    if(!downloadDocument.value.url){
        return;
    }
    if(downloadDocument.value.bucketStorage){
        openInNewTab(downloadDocument.value.url);
    }
    else{
        await generateHappERPFile();
    }
    
}
</script>

<style scoped>
.small-text {
    font-size: 1.1rem !important;
}
</style>
