import i18n from '@i18n';

const { t } = i18n.global; 

export function toLongDate(dbDate){
    if(dbDate == null || !Date.parse(dbDate)) {
        return t('inspectionDetail.noDate');
    }

    const dateOptions = {
        day: 'numeric',
        year: 'numeric',
        month: 'long'
    }
    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric'
    }

    const dateObj = new Date(dbDate);
    if(!isNaN(dateObj)){
        const dateString = dateObj.toLocaleDateString('nl-NL', dateOptions);
        const timeString = dateObj.toLocaleTimeString('nl-NL', timeOptions);
        if(timeString != '00:00'){
            return [dateString, t('at'), timeString].join(' ');
        }
        else{
            return dateString
        }
    }
    else{
        return t('inspectionDetail.noDate');
    }
}

export function toShortDate(dbDate){
    if(dbDate == null || !Date.parse(dbDate)) {
        return t('inspectionDetail.noDate');
    }

    const dateOptions = {
        day: 'numeric',
        year: 'numeric',
        month: 'long'
    }

    const dateObj = new Date(dbDate);
    if(!isNaN(dateObj)){
        const dateString = dateObj.toLocaleDateString('nl-NL', dateOptions);
        return dateString
    }
    else{
        return t('inspectionDetail.noDate');
    }
}

export function getTimeDifferenceInDays(date1, date2){
    const diffTime = date2 - date1;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}