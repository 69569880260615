<template>
    <v-container justify-center fluid>
        <v-row>
            <v-bottom-navigation grow horizontal elevation="2" class="w-full" v-if="showForRoutes.includes(route.path.substring(1))">

                <template v-for="navRoute of showForRoutes">
                    <v-btn size="x-small pa-0" @click="navigateTo(navRoute)">
                        <div v-if="route.path == `/${navRoute}`">
                            <v-icon color="base0">{{ `$${navRoute}_selected` }}</v-icon>
                            <p class="font-weight-bold text-primary">{{ $t(`bottomNav.${navRoute}`) }}</p>
                        </div>
                        <div v-else>
                            <v-icon color="base0">{{ `$${navRoute}` }}</v-icon>
                            <p>{{ $t(`bottomNav.${navRoute}`) }}</p>
                        </div>
                    </v-btn>
                </template>

            </v-bottom-navigation>
        </v-row>  
    </v-container>
</template>

<script setup>
import router from '@/router'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue';

// const showForRoutes = ref([
//     "dashboard",
//     "installations",
//     "monitoring",
//     "aftersale",
//     "support" 
// ]);

const showForRoutes = ref([
    "installations",
    "support" 
]);

const route = useRoute();

function navigateTo(path) {
    router.push({ name: `/${path}`})
}

</script>

<style scoped>
p {
    font-size: 0.9em;
    color: rgb(var(--v-theme-base400))
}
</style>