<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.75 19.5C21.75 19.6989 21.671 19.8897 21.5303 20.0303C21.3897 20.171 21.1989 20.25 21 20.25H3C2.80109 20.25 2.61032 20.171 2.46967 20.0303C2.32902 19.8897 2.25 19.6989 2.25 19.5C2.25 19.3011 2.32902 19.1103 2.46967 18.9697C2.61032 18.829 2.80109 18.75 3 18.75H3.75V12.75C3.75 12.5511 3.82902 12.3603 3.96967 12.2197C4.11032 12.079 4.30109 12 4.5 12H6.75C6.94891 12 7.13968 12.079 7.28033 12.2197C7.42098 12.3603 7.5 12.5511 7.5 12.75V18.75H9V8.25C9 8.05109 9.07902 7.86032 9.21967 7.71967C9.36032 7.57902 9.55109 7.5 9.75 7.5H12.75C12.9489 7.5 13.1397 7.57902 13.2803 7.71967C13.421 7.86032 13.5 8.05109 13.5 8.25V18.75H15V3.75C15 3.55109 15.079 3.36032 15.2197 3.21967C15.3603 3.07902 15.5511 3 15.75 3H19.5C19.6989 3 19.8897 3.07902 20.0303 3.21967C20.171 3.36032 20.25 3.55109 20.25 3.75V18.75H21C21.1989 18.75 21.3897 18.829 21.5303 18.9697C21.671 19.1103 21.75 19.3011 21.75 19.5Z" fill="#A0D52D"/>
</svg>

</template>

<style scoped>
path{
    stroke: rgb(var(--v-theme-primary));
    fill: rgb(var(--v-theme-primary));
}
</style>