<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 19.5H21V10.8328C21 10.6252 20.957 10.4199 20.8735 10.2298C20.7901 10.0397 20.6681 9.86895 20.5153 9.72843L13.0153 2.65218C13.0116 2.64898 13.0082 2.64554 13.005 2.64187C12.7289 2.39074 12.369 2.25159 11.9958 2.25159C11.6225 2.25159 11.2627 2.39074 10.9866 2.64187L10.9762 2.65218L3.48469 9.72843C3.33187 9.86895 3.20989 10.0397 3.12646 10.2298C3.04303 10.4199 2.99997 10.6252 3 10.8328V19.5H1.5C1.30109 19.5 1.11032 19.579 0.96967 19.7197C0.829018 19.8603 0.75 20.0511 0.75 20.25C0.75 20.4489 0.829018 20.6397 0.96967 20.7803C1.11032 20.921 1.30109 21 1.5 21H22.5C22.6989 21 22.8897 20.921 23.0303 20.7803C23.171 20.6397 23.25 20.4489 23.25 20.25C23.25 20.0511 23.171 19.8603 23.0303 19.7197C22.8897 19.579 22.6989 19.5 22.5 19.5ZM14.25 19.5H9.75V15C9.75 14.8011 9.82902 14.6103 9.96967 14.4697C10.1103 14.329 10.3011 14.25 10.5 14.25H13.5C13.6989 14.25 13.8897 14.329 14.0303 14.4697C14.171 14.6103 14.25 14.8011 14.25 15V19.5Z" fill="#A0D52D"/>
</svg>

</template>

<style scoped>
path{
    stroke: rgb(var(--v-theme-primary));
    fill: rgb(var(--v-theme-primary));
}
</style>